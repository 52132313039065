import React, { useState } from 'react';
import parseDate from '../utils/parseDate';


import { graphql } from "gatsby"
import Header from '../components/layout/HeaderLayout';

import CustomFooter from '../components/CustomFooter';
import GdprCookies from '../components/GdprCookies';
import "../theme/layout.scss"

import SearchIcon from '@material-ui/icons/Search';
import BasicsBg from '../assets/basics-bg.jpg';

import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';

import ModalSuscribe from '../components/layout/ModalSuscribre';

const WebinarList = ({data}) =>{


    const webinarList = data.webinar_qs.edges
    const hig = webinarList[0];

    //Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const todosPerPage = 5;
    const totalCards = webinarList.slice(1);
    const indexOfLastTodo = (currentPage * todosPerPage);
    const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    const cards = totalCards.slice(indexOfFirstTodo,indexOfLastTodo);
    const totalPages = Math.ceil(totalCards.length / todosPerPage);


    //functions modal suscribe
    const [open, setOpen] = useState(false);
    function openSuscribe(){
      setOpen(true);
    }
    function closeSuscribe(){
      setOpen(false);
    }

    // @ts-ignore
  // @ts-ignore
  return (
      <>
        <Header openModal={openSuscribe}/>

        <div className="header-section-bg">

          <div className='top-bg' style={{ backgroundImage: `url(${BasicsBg})` }}></div>

          <div className="container-sec">

            <div className='top-content'>
              <div className='top-title'>
                <h2>Webinar</h2>
              </div>
              <div className='top-search'>
                <form action="/buscar">
                  <input type="text" name='q' />
                  <SearchIcon className='icon--search'/>
                </form>
              </div>
            </div>

            <div className="top-item">
              <div className="top-tag">
                <p>documento destacado</p>
              </div>

              <div className="top-card">
                  <p className="date">{parseDate(hig.node.createdAt)}</p>
                  <h2 className="title">{hig.node.tituloDelWebinar}</h2>
                  <p className="resume-content">{hig.node.introduccionwebinar.introduccionwebinar}</p>
                  <div className='item--footer'>
                    {/*<p className="autor">{hig.node.autor.nombre}</p>*/}
                    <a href={`/webinar/${hig.node.slug}`} >
                        <div className="link">Ver más</div>
                    </a>
                  </div>
              </div>

            </div>



          </div>

        </div>

        <div className='wrap-content'>

          {cards && cards.map(({ node }) => {
            const { tituloDelWebinar, slug,createdAt,introduccionwebinar} = node
            return (

              <div className="item" key={slug}>
                <p className="date">{parseDate(createdAt)}</p>
                <h2 className="title">{tituloDelWebinar}</h2>
                <p className="resume-content">{introduccionwebinar.introduccionwebinar}</p>
                <div className="item--footer">
                  <p className="autor">{slug}</p>
                  <a href={`/webinar/${slug}`} >
                      <div className="link">Ver más</div>
                  </a>
                </div>
              </div>
            )
          })}
          <div className='wrap-pagination'>
            <div className='pagination'>
              <FirstPageIcon fontSize='small' onClick={()=>setCurrentPage(1)}/>
              <NavigateBeforeIcon fontSize='small' onClick={()=>{
                if(currentPage > 1){
                  setCurrentPage(currentPage-1)
                }
                console.log("index of", indexOfLastTodo)
                console.log("index first", indexOfFirstTodo)
              }}/>
              <div className='text'>{currentPage}</div>
              <div className='text de'>de</div>
              <div className='text'>{totalPages}</div>
              <NavigateNextIcon fontSize='small' onClick={()=>{
                if(currentPage < totalPages ){
                  setCurrentPage(currentPage+1);
                }
                console.log("index of", indexOfLastTodo)
                console.log("index first", indexOfFirstTodo)
              }}/>
              <LastPageIcon fontSize='small' onClick={()=>setCurrentPage(totalPages)}/>
            </div>
          </div>
        </div>
        <CustomFooter openModal={openSuscribe}/>
        <GdprCookies />
        {
          open && <ModalSuscribe closeModal={closeSuscribe}/>
        }
      </>

    )
}

export default WebinarList

export const pageQuery = graphql`
query MyQueryBasics{

    webinar_qs: allContentfulWebinar(
        sort: { fields: createdAt, order: DESC }
      ) {
    edges {
      node {
        slug
        createdAt
        tituloDelWebinar
        youtubelink
        introduccionwebinar {
          introduccionwebinar
        }
      }
    }
  }


  }
`;
